import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick";  //eslint-disable-line no-unused-vars
import axios from "axios";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'
import bestFinalist from '../../resource/GROCBestFinalist.json'

export const DevServer = "https://dev-apis.981park.net"
export const RealServer = "https://apis-jeju.981park.com"
export const DevChampionServer = "https://dev-api.sgp.981park.com/champion"
export const RealChampionServer = "https://api.sgp.981park.com/champion"

const headers = {
	'Content-Type': 'application/json;charset=UTF-8',
	'X-Authorization-Client-Secret': '586f474c-50b1-11eb-b88b-02d92b41ce80',
	'X-Authorization-Client-Id': '981park-customer-home'
}

class NewMGrocComp  extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			grocYear: "2025",
			ddayT: "0",
			ddayN: "0",
			ddayCheck: false,
			dayCheck: false,
			bannerCheck: false,
			bestFinalCheck: false,
			tabType: "best",
			roundDropDown: false,
			bestProfilePopup: false,
			selectBestProfile: null,
			selectRoundProfile: null,
			roundProfilePopup: false,
			teamProfilePopup: false,
			selectTeamProfile: null,
			pointList: [],
			pointOnOffList: [],
			currentRound: null,
			removeAni: false,
			toggle: false,
			bestRanking: [],
			roundRanking: [],
			realRanking: [],
			teamRanking: []
		};
		var season = null  //eslint-disable-line no-unused-vars
		var schId = null  //eslint-disable-line no-unused-vars
		var roundList = [];  //eslint-disable-line no-unused-vars
	}

	componentDidMount() {
		var today = new Date()
		var dday = new Date('2025-12-07')
		var params = new URLSearchParams(window.location.search);
		if (params.get("today") !== null) {
			dday = new Date(params.get("today"))
		}
		var gap = dday.getTime() - today.getTime()
		var result = Math.ceil(gap /(1000 * 60 * 60 * 24))

		if (result <= 0) {
			this.setState({ddayCheck: true},  this.animationStart("real"))

			/*if (window.location.hostname === "localhost" || window.location.hostname === "dev-home.981park.net") {
				this.server = DevChampionServer
			} else {
				this.server = RealChampionServer
			}*/
			this.server = RealChampionServer

			this.requestYear()
		} else {
			/*if (window.location.hostname === "localhost" || window.location.hostname === "dev-home.981park.net") {
				this.server = DevServer
			} else {
				this.server = RealServer
			}*/
			this.server = RealServer

			this.requestBest()
			this.requestSeason()
		}

		var str = result.toString()
		if (str.length === 1 && str === 0) {
			this.setState({ddayT: "day",ddayN: ""})
		} else if (str.length === 1) {
			this.setState({ddayT: "0",ddayN: str})
		} else {
			this.setState({ddayT: str.charAt(0), ddayN: str.charAt(1)})
		}

		/*this.requestRealTimeCheck(true)*/

		this.aniInterval = setInterval(() => {
			this.setState({removeAni: true})
			clearInterval(this.aniInterval)
		}, 5000);
		window.scrollTo(1, 0);
	}

	/*requestRealTimeCheck(first) {
		axios({
			url:`${this.server}/customer/data/groc/main`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.schId = response.data.data.schId
			this.requestRealTimeRanking(first)
		})
	}

	requestRealTimeRanking(first) {
		axios({
			url:`${this.server}/customer/data/groc/ranking?schId=${this.schId}`,
			method: 'get',
			headers: headers
		}).then(response => {
			if (first === true) {
				this.setState({realRanking: response.data.data.rankingTotal.rankingList})
			} else {
				this.setState({realRanking: response.data.data.rankingTotal.rankingList, tabType: "scrollReset"}, this.changeTap("real"))
			}
			// var list = response.data.data.rankingTotal.rankingList
			// const numAscending = [...list].sort((a, b) => {
			// 	if (a.xlLaptime === null || a.xlLaptime === 0) {
			// 		return true
			// 	}else if (b.xlLaptime === null || b.xlLaptime === 0){
			// 		return false
			// 	} else {
			// 		return a.xlLaptime - b.xlLaptime
			// 	}

			// });
			// if (first === true) {
			// 	this.setState({realRanking: numAscending})
			// } else {
			// 	this.setState({realRanking: numAscending, tabType: "scrollReset"}, this.changeTap("real"))
			// }
		})
	}*/

	requestBest() {
		axios({
			url:`${this.server}/customer/data/groc/bestLapRanking?offset=0&limit=100`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({bestRanking: response.data.data.rankingList})
		})

	}

	requestSeason() {
		axios({
			url:`${this.server}/customer/data/groc/seasons`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.season = response.data.data[response.data.data.length-1];
			this.requestRoundList()
		})
	}

	requestRoundList() {
		axios({
			url:`${this.server}/customer/data/groc/rounds?seaId=${this.season.seaId}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.roundList = response.data.data;
			if (this.roundList.length > 1) {
				if (this.roundList[0].roundName.includes("Pre-Round")) {
					this.roundList.shift()
				}
				if (this.roundList[this.roundList.length - 1].roundName.includes("Post-Round")) {
					this.roundList.pop()
				}
			}
			this.setState({currentRound:this.roundList[this.roundList.length - 1]}, this.requestRoundRanking)
		})
	}

	requestRoundRanking(rndId) {
		if (this.roundList.length > 0) {
			axios({
				url:`${this.server}/customer/data/groc/roundRanking?rndId=${this.state.currentRound.rndId}&lgeId=3&offset=0&limit=20`,
				method: 'get',
				headers: headers
			}).then(response => {
				if (response.data.data != null) {
					if (response.data.data.length > 10) {
						this.setState({roundRanking: response.data.data.splice(0, 10)})
					} else {
						this.setState({roundRanking: response.data.data})
					}
				} else {
					this.setState({roundRanking: ''})
				}
			})
		}
	}

	requestYear() {
		axios({
			url:`${this.server}/homepage/years`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.seasonList = response.data.data
			this.requestReal()
			this.requestTeam()
		})
	}

	requestReal() {
		axios({
			url:`${this.server}/homepage/singles/${this.seasonList[0].championsSeq}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({realRanking: response.data.data})
		})
	}

	requestTeam() {
		axios({
			url:`${this.server}/homepage/teams/${this.seasonList[0].championsSeq}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({teamsRanking: response.data.data, teamSfMatch1: response.data.data.sfMatch1, teamSfMatch2: response.data.data.sfMatch2, teamFinalMatch: response.data.data.finalMatch})
		})
	}

	/*animationStart = (tabType) => () => {
		if (tabType == "real") {
			this.setState({tabType: "scrollReset"}, this.changeTap(tabType))
			this.realInterval = setInterval(() => {
				this.requestRealTimeCheck(false)
			}, 60000);
		} else if (this.state.tabType != tabType) {
			clearTimeout(this.realInterval)
			this.setState({tabType: "scrollReset"}, this.changeTap(tabType))
		}
	}*/

	animationStart = (tabType) => () => {
		this.setState({tabType: "scrollReset"}, this.changeTap(tabType))
	}

	changeTap = (tabType) => () => {
		this.setState({tabType: tabType, toggle: true}, this.animationEnd)
	}

	showBestPopup = (ranking) => () => {
		this.setState({selectBestProfile: ranking, bestProfilePopup: true})
	}

	hideBestPopup = () => () => {
		this.setState({selectBestProfile: null, bestProfilePopup: false})
	}

	showRoundPopup = (round) => () => {
		axios({
			url:`${this.server}/customer/data/groc/points/${round.rndId}/3?memberId=${round.memberId}&raceRankingVersion=${round.raceRankingVersion}`,
			method: 'get',
			headers: headers
		}).then(response => {
			this.setState({selectRoundProfile: round, pointList: response.data.data.details, pointOnOffList: Array(response.data.data.details.length).fill(false),roundProfilePopup: true})
		})

	}

	hideRoundPopup = () => () => {
		this.setState({selectRoundProfile: null, pointList: [], pointOnOffList: [], roundProfilePopup: false})
	}

	showTeamPopup = (teamsMatch,matchTitle) => () => {
		this.setState({selectTeamProfile: teamsMatch, selectTeamTitle: matchTitle, teamProfilePopup: true})
	}

	hideTeamPopup = () => () => {
		this.setState({selectTeamProfile: null, teamProfilePopup: false})
	}

	animationEnd = () => {
		this.listInterval = setInterval(() => {
			this.setState({toggle: false}, clearInterval(this.listInterval))
		}, 100);
	}

	selectRound = (round) => () => {
		this.setState({currentRound:round, roundDropDown: false, tabType: "scrollReset"}, () => {
			this.setState({tabType: "round"}, this.requestRoundRanking)
		})
	}

	openRoundDropDown = () => {
		this.setState({roundDropDown: !this.state.roundDropDown})
	}

	openClosePoint = (i) => () => {
		let copyArray = [...this.state.pointOnOffList];
		copyArray[i] = !copyArray[i]
		this.setState({pointOnOffList: copyArray})
	}

	getOrdinal(idx) {
		if (idx === null) {
			return ""
		} else if (idx === 1) {
			return "st"
		} else if (idx === 2) {
			return "nd"
		} else if (idx === 3) {
			return "rd"
		} else {
			return "th"
		}
	}

	convertTime(milli) {
		if (milli === 0) {
			return "N/A"
		} else {
			let time = new Date(milli);
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			let milliseconds = time.getUTCMilliseconds();
			var secondStr = ""
			var milStr = ""
			if (milliseconds < 10) {
				milStr = "00" + milliseconds
			} else if (milliseconds < 100) {
				milStr = "0" + milliseconds
			} else {
				milStr = "" + milliseconds
			}
			if (seconds < 10) {
				secondStr = "0" + seconds
			} else {
				secondStr = "" + seconds
			}
			return minutes + "’" + secondStr + "”" + milStr;
		}
	}

	convertTimeReal(milli) {
		if (milli === null) {
			return "READY"
		}else if (milli === 0) {
			return "READY"
		} else {
			let time = new Date(milli);
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			let milliseconds = time.getUTCMilliseconds();
			var secondStr = ""
			var milStr = ""
			if (milliseconds < 10) {
				milStr = "00" + milliseconds
			} else if (milliseconds < 100) {
				milStr = "0" + milliseconds
			} else {
				milStr = "" + milliseconds
			}
			if (seconds < 10) {
				secondStr = "0" + seconds
			} else {
				secondStr = "" + seconds
			}
			return minutes + "’" + secondStr + "”" + milStr;
		}
	}

	convertTimeTeam(milli) {
		if (milli === null) {
			return "READY"
		}

		let time = new Date(milli);
		let minutes = time.getUTCMinutes();
		let seconds = time.getUTCSeconds();
		let milliseconds = time.getUTCMilliseconds();
		var secondStr = ""
		var milStr = ""
		if (milliseconds < 10) {
			milStr = "00" + milliseconds
		} else if (milliseconds < 100) {
			milStr = "0" + milliseconds
		} else {
			milStr = "" + milliseconds
		}
		if (seconds < 10) {
			secondStr = "0" + seconds
		} else {
			secondStr = "" + seconds
		}
		return minutes + "’" + secondStr + "”" + milStr;
	}

	convertTimePenalty(milli) {
		if (milli === null) {
			return ""
		} else {
			let time = new Date(milli);
			let minutes = time.getUTCMinutes();
			let seconds = time.getUTCSeconds();
			var secondStr = ""
			var milStr = ""
			if (seconds < 10) {
				secondStr = "0" + seconds
			} else {
				secondStr = "" + seconds
			}
			return minutes + "m" + secondStr + "s" + milStr;
		}
	}

	hexColorToRGB(hex, alpha) {
		if (typeof hex !== 'string' || hex[0] !== '#') return null;

		const stringValues = (hex.length === 4)
			? [hex.slice(1, 2), hex.slice(2, 3), hex.slice(3, 4)].map(n => `${n}${n}`)
			: [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)];
		const intValues = stringValues.map(n => parseInt(n, 16));

		return (typeof alpha === 'number')
			? `rgba(${intValues.join(', ')}, ${alpha})`
			: `rgb(${intValues.join(', ')})`;
	}

	makeBest() {
		if(this.state.bestFinalCheck === true){
			var bestRanking = bestFinalist
		}else{
			var bestRanking = this.state.bestRanking
		}

		var grocYear = this.state.grocYear
		var rankings = []
		var len = bestRanking.length
		for (var i=0; i<len; i++) {
			var classN = "row"
			if (i < 3) {
				classN = classN + " groc-rk"
			}
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{bestRanking[i].ranking}</span>
							<span className="label typo1">{this.getOrdinal(bestRanking[i].ranking)}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="name"><span className="txt">{bestRanking[i].nickName}</span></div>
							<div className="lab-time">
								<span className="num typo2">{this.convertTime(bestRanking[i].xlLaptime + bestRanking[i].xrLaptime)}</span>
							</div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showBestPopup(bestRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		return <div className={"tab-con best-raking " + (this.state.toggle ? "" : "on") + (this.state.removeAni ? "" : " visual-ani")}>
			<div className="visual-wrap">
				<div className="bg"></div>
				<div className="visual">
					<div className="ttl typo1">UNLIMIT <br/> YOUR <br/> LIMIT</div>
				</div>
				<div className="logo"><img src={"https://asset.981park.com/www/common/mweb/img/sub/groc/graphic-emblem-groc-champion-"+ (grocYear) +".svg"} alt=""/></div>
			</div>
			<div className="scroll-wrap">
				<div className="ranking-list">
					<div className="list">
						{rankings}
					</div>
				</div>
			</div>
		</div>
	}

	makeRound() {
		var rankings = []
		var len = this.state.roundRanking.length
		for (var i=0; i<len; i++) {
			var classN = "row"
			if (i < 3) {
				classN = classN + " groc-rk"
			}
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{this.state.roundRanking[i].ranking}</span>
							<span className="label typo1">{this.getOrdinal(this.state.roundRanking[i].ranking)}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="name"><span className="txt">{this.state.roundRanking[i].nickName}</span></div>
							<div className="lab-time">
								<span className="num typo2">{this.state.roundRanking[i].point}</span>
								<span className="label">RP</span>
							</div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showRoundPopup(this.state.roundRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		var round = []
		for (var i=0; i<this.roundList.length; i++) {
			round.push(<li><button type="button" onClick={this.selectRound(this.roundList[i])}>{this.roundList[i].roundName}</button></li>);
		}
		if (this.roundList !== null) {
			return <div className={"tab-con round-raking" + (this.state.toggle ? "" : " on")}>
				<div className="select-round-wrap">
					<div className="select-round">
						<button className={"btn-select" + (this.state.roundDropDown ? " on" : "")} onClick={() => this.openRoundDropDown()}>{this.state.currentRound.roundName}</button>
						<div className="list-round">
							<ul>
								{round}
							</ul>
						</div>
					</div>
					<div className="txt-area">
						<p>Finished {(this.state.currentRound.endDt).replaceAll('-', '.')}</p>
					</div>
				</div>
				<div className="scroll-wrap">
					<div className="ranking-list">
						<div className="list">
							{rankings}
						</div>
					</div>
				</div>
			</div>
		} else {
			return null
		}
	}

	makeReal() {
		var grocYear = this.state.grocYear
		var rankings = []
		var len = this.state.realRanking.length
		for (var i=0; i<len; i++) {
			var classN = "row"
			if (i < 3) {
				classN = classN + " groc-rk"
			}
			var rankStr = "-"
			var ordinalStr = ""
			if (this.state.realRanking[i].ranking !== null) {
				rankStr = this.state.realRanking[i].ranking + ""
				ordinalStr = this.getOrdinal(this.state.realRanking[i].ranking)
			}
			rankings.push(<div className={classN}>
				<ul>
					<li>
						<div className="rk">
							<span className="num typo2">{rankStr}</span>
							<span className="label typo1">{ordinalStr}</span>
						</div>
					</li>
					<li>
						<div className="rk-user">
							<div className="name"><span className="txt">{this.state.realRanking[i].nickName}</span></div>
							<div className="lab-time">
								<span className="num typo2">{this.state.realRanking[i].dnfYn ? "N/A" :  this.convertTimeReal(this.state.realRanking[i].xlLaptime + this.state.realRanking[i].xrLaptime)}</span>
							</div>
						</div>
					</li>
					<li>
						<div className="btns">
							<button className="btn btn-popup" onClick={this.showBestPopup(this.state.realRanking[i])}><span className="blind">자세히 보기</span></button>
						</div>
					</li>
				</ul>
			</div>)
		}

		return <div className={"tab-con best-raking " + (this.state.toggle ? "" : "on") + (this.state.removeAni ? "" : " visual-ani")}>
			<div className="visual-wrap">
				<div className="bg"></div>
				<div className="visual">
					<div className="ttl typo1">UNLIMIT <br/> YOUR <br/> LIMIT</div>
				</div>
				<div className="logo"><img src={"https://asset.981park.com/www/common/mweb/img/sub/groc/graphic-emblem-groc-champion-"+ (grocYear) +".svg"} alt=""/></div>
			</div>
			<div className="scroll-wrap">
				<div className="ranking-list">
					<div className="list">
						{rankings}
					</div>
				</div>
			</div>
		</div>
	}

	makeTeam() {
		var matchStatusClass = ""
		var sfStatus1 = this.state.teamSfMatch1.status.toLowerCase()
		var sfStatus2 = this.state.teamSfMatch2.status.toLowerCase()
		var finalStatus = this.state.teamFinalMatch.status.toLowerCase()

		if(sfStatus1==="end" && sfStatus2==="end"){
			matchStatusClass = "end"
		} else if(sfStatus1==="race" || sfStatus2==="race"){
			matchStatusClass = "race"
		} else {
			matchStatusClass = "ready"
		}

		const rendering = (idx) => {
			var teamsMatch = ""
			var matchStatus = ""
			var matchTitle = ""

			if (idx === 0) {
				teamsMatch = this.state.teamSfMatch1
				matchStatus = sfStatus1
				matchTitle = "SF.MATCH 1"
			} else if (idx === 1) {
				teamsMatch = this.state.teamSfMatch2
				matchStatus = sfStatus2
				matchTitle = "SF.MATCH 2"
			}  else {
				teamsMatch = this.state.teamFinalMatch
				matchStatus = finalStatus
				matchTitle = "FINAL MATCH"
			}

			var result = []
			var teamsList = []

			var len = teamsMatch.teams.length
			for (var i=0; i<len; i++) {
				if(matchStatus === 'race'){
					var teamBgColor= {backgroundImage: `linear-gradient(to right, ${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 0.3)} 0%, ${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 0)} 100%)`}
					var teamNameColor= {color: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
					var captainColor= {borderColor: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
					var captainLabelColor= {backgroundColor: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
				} else if(matchStatus !== 'end'){
					var teamNameColor= {color: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
					var captainColor= {borderColor: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
					var captainLabelColor= {backgroundColor: `${this.hexColorToRGB(teamsMatch.teams[i].teamColor, 1)}`}
				}

				var playersList = []
				var playersLen = teamsMatch.teams[i].players.length
				for (var j=0; j<playersLen; j++) {
					var srcString = ""
					if (teamsMatch.teams[i].players[j].thumbnailImageUrl === null) {
						srcString = "https://asset.981park.com/www/common/web/img/sub/groc/icon-profile-empty-large.png"
					} else {
						srcString = teamsMatch.teams[i].players[j].thumbnailImageUrl
					}

					if (teamsMatch.teams[i].players[j].captainYn === false) {
						playersList.push(<div className="member">
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
					} else {
						playersList.push(<div className="member leader" style={captainColor}>
							<span className="label typo1" style={captainLabelColor}>c</span>
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
					}
				}

				var matchResult = ""
				matchResult = teamsMatch.teams[i].result.toLowerCase()

				teamsList.push(<div className={"teams " + (matchResult==="match" ? "" : matchResult)} style={teamBgColor}>
					<div className="team-member">
						{playersList}
					</div>
					{(teamsMatch.teams[i].teamName === null) ? <div className="empty typo1">{"M" + (i+1) + " WINNER"}</div> :
						<div className="team-name"><span className="txt" style={teamNameColor}>{teamsMatch.teams[i].teamName}</span></div>}
				</div>)
			}

			result.push(<div className={"match " + (matchStatus)}>
				<div className="top-area">
					<div className="ttl-area">
						<span className="ttl typo1">{matchTitle}</span> <span className="label"><span className="blind">RACE</span></span>
					</div>
					{(idx === 2) ? <div className="time">
						<span className="num typo1">{teamsMatch.startTime}</span>
					</div> : null}
					<div className="btns">
						<button className="btn btn-popup" onClick={this.showTeamPopup(teamsMatch,matchTitle)}><span className="txt">상세보기</span></button>
					</div>
				</div>
				<div className="teams-list">
					{teamsList}
				</div>
			</div>)

			return result;
		};

		return <div className={"tab-con teams-tournament " + (this.state.toggle ? "" : "on")}>
			<div className="bg"></div>
			<div className="scroll-wrap">
				<div className="teams-tournament-bracket-wrap">
					<div className="teams-tournament-bracket">
						<div className={"semi-final-match " + (matchStatusClass)}>
							<div className="ttl typo3">SEMI-FINAL MATCH</div>
							<div className="match-list">
								{rendering(0)}
								{rendering(1)}
							</div>
						</div>
						<div className={"final-match " + (finalStatus)}>
							<div className="ttl">
								<span className="txt typo3">FINAL</span>
								<span className="txt typo3">MATCH</span>
							</div>
							<div className="match-list">
								{rendering(2)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}

	render() {
		const {t} = this.props;
		var grocYear = this.state.grocYear
		var contents = null
		var tabName = ""
		if (this.state.tabType === "best") {
			tabName = "selected"
			contents = this.makeBest()
		} else if (this.state.tabType === "round") {
			tabName = ""
			contents = this.makeRound()
		} else if (this.state.tabType === "real") {
			tabName = "selected"
			contents = this.makeReal()
		} else if (this.state.tabType === "team") {
			tabName = ""
			contents = this.makeTeam()
		}

		var rankingTitle = ""
		if (this.state.bestFinalCheck === true) {
			rankingTitle = t('Groc_best_lap_finalist')
		} else {
			rankingTitle = t('Groc_all_round_ranking')
		}

		var bestPopup = null
		if (this.state.bestProfilePopup) {
			var rankStr = "-"
			if (this.state.selectBestProfile.ranking !== null) {
				rankStr = this.state.selectBestProfile.ranking + this.getOrdinal(this.state.selectBestProfile.ranking)
			}
			if (this.state.ddayCheck !== true) {
				var srcString = ""
				if (this.state.selectBestProfile.image === null) {
					srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
				} else {
					srcString = this.state.selectBestProfile.image
				}
				bestPopup = <div className="layer-popup groc-popup groc-best-popup">
					<div className="layer-popup-inner">
						<div className="content">
							<button type="button" className="btn close" onClick={this.hideBestPopup()}><span className="blind">닫기</span></button>
							<div className="con">
								<div className="detail-ranking groc-rk">
									<div className="row rk-user">
										<div className="img-wrap"><span className="img"><img src={srcString} alt=""/></span></div>
										<div className="name"><span className="txt">{this.state.selectBestProfile.nickName}</span></div>
										<div className="lab-time"><span className="num">{this.convertTimeReal(this.state.selectBestProfile.xlLaptime + this.state.selectBestProfile.xrLaptime)}</span></div>
									</div>

									<div className="row">
										<div className="track">
											<span className="sm-txt">{t('Groc_l_lap')}</span>
											<span className="num">{this.convertTimeReal(this.state.selectBestProfile.xlLaptime)}</span>
										</div>
										<div className="track">
											<span className="sm-txt">{t('Groc_r_lap')}</span>
											<span className="num">{this.convertTimeReal(this.state.selectBestProfile.xrLaptime)}</span>
										</div>
									</div>

									<div className="row">
										<div className="ranking">
											<span className="sm-txt">{t('Groc_total')}</span>
											<span className="num">{rankStr}</span>
										</div>
										<div className="ranking">
											<span className="sm-txt">{t('Groc_round_rank')}</span>
											<span className="label rk1">
															<span className="round">{this.state.selectBestProfile.roundName.replaceAll(" ","")}</span>
															<span className="rk">{this.state.selectBestProfile.roundRanking + this.getOrdinal(this.state.selectBestProfile.roundRanking)}</span>
														</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			} else {
				var srcString = ""
				if (this.state.selectBestProfile.thumbnail === null) {
					srcString = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
				} else {
					srcString = this.state.selectBestProfile.thumbnailImageUrl
				}
				bestPopup = <div className={"layer-popup groc-popup groc-ranking-popup "  + (this.state.selectBestProfile.dnfYn ? "fs-popup" : "")}>
					<div className="layer-popup-inner">
						<div className="content">
							<button type="button" className="btn close" onClick={this.hideBestPopup()}><span className="blind">닫기</span></button>
							<div className="con">
								<div className="detail-ranking groc-rk">
									<div className="row rk-user">
										<div className="img-wrap"><span className="img"><img src={srcString} alt=""/></span></div>
										<div className="name"><span className="txt">{this.state.selectBestProfile.nickName}</span></div>
										<div className="lab-time">
											<span className="num">{(this.state.selectBestProfile.dnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xlLaptime + this.state.selectBestProfile.xrLaptime)}</span>
										</div>
									</div>

									<div className="row">
										<div className="track">
											<span className="sm-txt">{t('Groc_l_lap')}</span>
											<span className="num">{(this.state.selectBestProfile.xlDnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xlLaptime)}</span>
											{this.state.selectBestProfile.xlFalseStartYn ? <span className="label">FS</span> : null}
										</div>
										<div className="track">
											<span className="sm-txt">{t('Groc_r_lap')}</span>
											<span className="num">{(this.state.selectBestProfile.xrDnfYn === true) ? "N/A" : this.convertTimeReal(this.state.selectBestProfile.xrLaptime)}</span>
											{this.state.selectBestProfile.xrFalseStartYn ? <span className="label">FS</span> : null}
										</div>
										<div className="ranking">
											<span className="sm-txt">{t('Groc_rank')}</span>
											<span className="num">{this.state.selectBestProfile.dnfYn ? "실격" : rankStr}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		}

		var roundPopup = null
		if (this.state.roundProfilePopup) {
			var len = this.state.pointList.length
			var pointsDiv = []
			for (var i=0; i<len; i++) {
				pointsDiv.push(<li>
					<div className={"accordion-ttl" + (this.state.pointOnOffList[i] === true ? " on" : "")} onClick={this.openClosePoint(i)}>
						<div className="ttl">{this.state.pointList[i].raceName + " " + "TRACK " + this.state.pointList[i].courseName}</div>
						<div className="lab-time">
							<span className="num">{this.state.pointList[i].point}</span>
							<span className="label">RP</span>
						</div>
					</div>
					<div className="accordion-con">
						<div className="point">
							<span className="ttl">랭킹</span>
							<span className="num">{this.state.pointList[i].rankingPoint + "P"}</span>
						</div>
						<div className="point">
							<span className="ttl">미션</span>
							<span className="num">{this.state.pointList[i].missionPoint + "P"}</span>
						</div>
						<div className="point">
							<span className="ttl">테크니컬</span>
							<span className="num">{this.state.pointList[i].bonusPoint + "P"}</span>
						</div>
					</div>
				</li>)
			}
			var srcStr = ""
			if (this.state.selectRoundProfile.imgUrl === null) {
				srcStr = "https://asset.981park.com/www/common/mweb/img/sub/groc/icon-profile-empty-large.png"
			} else {
				srcStr = this.state.selectRoundProfile.imgUrl
			}
			roundPopup = <div className="layer-popup groc-popup groc-round-popup">
				<div className="layer-popup-inner">
					<div className="content">
						<button type="button" className="btn close" onClick={this.hideRoundPopup()}><span className="blind">닫기</span></button>
						<div className="con">
							<div className="detail-ranking groc-rk">
								<div className="row rk-user">
									<div className="img-wrap"><span className="img"><img src={srcStr} alt=""/></span></div>
									<div className="name"><span className="txt">{this.state.selectRoundProfile.nickName}</span></div>
									<div className="lab-time">
										<span className="num">{this.state.selectRoundProfile.point}</span>
										<span className="label">RP</span>
									</div>
								</div>
								<div className="accordion-wrap">
									<div className="accordion-head">
										<div className="ttl">{t('Groc_racing_point')}</div>
									</div>
									<div className="accordion-contents">
										<ul className="accordion">
											{pointsDiv}
										</ul>
									</div>
									<div className="accordion-foot">
										<div className="ttl">{t('Groc_rank')}</div>
										<div className="num">{this.state.selectRoundProfile.ranking + this.getOrdinal(this.state.selectRoundProfile.ranking)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		var teamPopup = null
		if (this.state.teamProfilePopup) {

			var teamsList = []
			var teamsMemberList = []
			var len = this.state.selectTeamProfile.teams.length

			for (var i=0; i<len; i++) {
				var playersList = []
				var playersInfo = []
				var playersLen = this.state.selectTeamProfile.teams[i].players.length
				for (var j=0; j<playersLen; j++) {
					var player = []
					var srcString = ""
					if (this.state.selectTeamProfile.teams[i].players[j].thumbnailImageUrl === null) {
						srcString = "https://asset.981park.com/www/common/web/img/sub/groc/icon-profile-empty-large.png"
					} else {
						srcString = this.state.selectTeamProfile.teams[i].players[j].thumbnailImageUrl
					}

					if (this.state.selectTeamProfile.teams[i].players[j].captainYn === false) {
						playersList.push(<div className="member">
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
						player.push(<div className="member">
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
					} else {
						playersList.unshift(<div className="member leader">
							<span className="label typo1">c</span>
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
						player.unshift(<div className="member leader">
							<span className="label typo1">c</span>
							<span className="img"><img src={srcString} alt=""/></span>
						</div>)
					}

					playersInfo.push(<li className="row">
						<div className="member-info">
							{player}
							<div className="member-name">
								<span className="txt">{this.state.selectTeamProfile.teams[i].players[j].nickName}</span>
							</div>
							<div className="lab-time">
								<span className="num typo2">{(this.state.selectTeamProfile.teams[i].players[j].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].players[j].dnfYn === true) ? "N/A" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].players[j].laptime)}</span>
							</div>
						</div>
						<div className="tracks">
							<div className="track">
								<span className="num typo2">{(this.state.selectTeamProfile.teams[i].players[j].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].players[j].xlDnfYn === true) ? "N/A" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].players[j].xlLaptime)}</span> {(this.state.selectTeamProfile.teams[i].players[j].xlFalseStartYn === true) ? <span className="fs"><span className="blind">FS</span></span> : null} {(this.state.selectTeamProfile.teams[i].players[j].xlTimePenaltyYn === true) ? <span className="tp"><span className="blind">TP</span></span> : null}
							</div>
							<div className="track">
								<span className="num typo2">{(this.state.selectTeamProfile.teams[i].players[j].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].players[j].xrDnfYn === true) ? "N/A" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].players[j].xrLaptime)}</span> {(this.state.selectTeamProfile.teams[i].players[j].xrFalseStartYn === true) ? <span className="fs"><span className="blind">FS</span></span> : null} {(this.state.selectTeamProfile.teams[i].players[j].xrTimePenaltyYn === true) ? <span className="tp"><span className="blind">TP</span></span> : null}
							</div>
						</div>
					</li>)
				}

				var matchResult = ""
				matchResult = this.state.selectTeamProfile.teams[i].result.toLowerCase()

				teamsList.push(<div className={"teams " + (matchResult==="match" ? "" : matchResult)}>
					<div className="match-results">
						<span className="txt typo3">{this.state.selectTeamProfile.teams[i].result}</span>
					</div>
					<div className="team-name">
						<span className="txt">{this.state.selectTeamProfile.teams[i].teamName}</span>
					</div>
					<div className="team-member">
						{playersList}
					</div>
					{(this.state.selectTeamProfile.teams[i].timePenaltyYn === true) ? <div className="tp">
						<span className="label">TP</span> <span className="penalty">+<span>{this.convertTimePenalty(this.state.selectTeamProfile.teams[i].totalPenaltyLaptime)}</span> penalty</span>
					</div> : null}
					<div className="state"><span className="txt typo2">{(this.state.selectTeamProfile.teams[i].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].dnfYn === true) ? "DNF" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].totalLaptime)}</span></div>
					<div className="tracks">
						<div className="track">
							<span className="num typo2">{(this.state.selectTeamProfile.teams[i].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].xlDnfYn === true) ? "N/A" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].xlTotalLaptime)}</span>
						</div>
						<div className="track">
							<span className="num typo2">{(this.state.selectTeamProfile.teams[i].readyYn === true) ? "READY" : (this.state.selectTeamProfile.teams[i].xrDnfYn === true) ? "N/A" : this.convertTimeTeam(this.state.selectTeamProfile.teams[i].xrTotalLaptime)}</span>
						</div>
					</div>
				</div>)

				teamsMemberList.push(<div className="member-list">
					<ul className="list">
						{playersInfo}
					</ul>
				</div>)

			}
			teamPopup = <div className="layer-popup groc-popup groc-team-popup">
				<div className="layer-popup-inner">
					<div className="content">
						<button type="button" className="btn close" onClick={this.hideTeamPopup()}><span className="blind">닫기</span></button>
						<div className="con">
							<div className="ttl">
								<span className="txt typo3">{this.state.selectTeamTitle}</span>
							</div>
							<div className={"match " + (this.state.selectTeamProfile.status.toLowerCase()==="match" ? "" : this.state.selectTeamProfile.status.toLowerCase())}>
								<div className="inner">
									<div className="match-teams">
										{teamsList}
									</div>

									<div className="match-teams-member">
										{teamsMemberList}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		var menu = null
		if (this.state.ddayCheck === true) {
			menu = (<ul className="tab-menu">
				<li className={tabName} onClick={this.animationStart("real")} style={{cursor: "pointer"}}><button>{t('Groc_singles_ranking')}</button></li>
				<li className={this.state.tabType === "team" ? "selected" : ""} onClick={this.animationStart("team")} style={{cursor: "pointer"}}><button>{t('Groc_teams_ranking')}</button></li>
			</ul>)
		} else {
			menu = (<ul className="tab-menu">
				<li className={tabName} onClick={this.animationStart("best")} style={{cursor: "pointer"}}><button>{rankingTitle}</button></li>
				<li className={this.state.tabType === "round" ? "selected" : ""} onClick={this.animationStart("round")} style={{cursor: "pointer"}}><button>{t('Groc_round_top_10')}</button></li>
			</ul>)
		}

		var banner = null
		if (this.state.bannerCheck !== false) {
			banner = <div className="sub-groc-banner">
				<a href={"https://asset.981park.com/www/common/mweb/img/pdf/GROC-" + (grocYear) + "-Championship-Final-Rule.pdf"} target="_blank" rel="noopener noreferrer" className="btn-groc-rule"><span className="txt">GROC {t('Groc_rule')}</span></a>
				<div className="marquee">
					<div className="banner banner1">
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
					</div>
					<div className="banner banner2">
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">UNLIMIT YOUR LIMIT</div>
						<div className="img"><img
							src="https://asset.981park.com/www/common/mweb/img/sub/groc/icon-mascot-banner.svg" alt=""/>
						</div>
						{this.state.dayCheck === true ? <div className="d-day">D-{this.state.ddayT + this.state.ddayN}</div> : null}
						<div className="txt">GROC {grocYear} START</div>
					</div>
				</div>
			</div>
		}
		return (
			<>
				<div
					className={"content-wrap sub-content sub-groc sub-groc-main sub-groc-v3" + (this.state.ddayCheck ? "" : " sub-groc-red") + (this.state.bannerCheck ? " add-sub-groc-banner" : "")}>
					<div className="sub-groc-main-content">
						{banner}
						<div className="sub-groc-left">
							{menu}
							<ul className="link-menu">
								<li><Link to={`/`+RoutePath.ABOUTGROC}>{t('Groc_about')}</Link></li>
								<li><Link to={`/`+RoutePath.HALLOFFAME}>{t('Groc_hall')}</Link></li>
							</ul>
						</div>

						<div className="sub-groc-right">
							<div className="tab-con-wrap">
								{contents}
							</div>
						</div>
					</div>
				</div>
				{bestPopup}
				{roundPopup}
				{teamPopup}
			</>
		);
	}
}
export default withTranslation()(NewMGrocComp);