import React from 'react';

class MPromoHotelComp extends React.Component {

    constructor(props) {
        super(props);
    }

	render() {
        const {t} = this.props;

		return (
            <div className="park-wrap park-promo promo-hotel">
                <header>
                    <div className="head-wrap">
                        <h1 className="logo">
                            <img src="https://asset.981park.com/www/common/mweb/img/common/logo/981-park.svg" alt="981 PARK"/>
                        </h1>
                    </div>
                </header>
                <div className="content-wrap">
                    <div className="visual-wrap">
                        <div className="video">
                            <video autoPlay muted loop playsInline
                                   src="https://asset.981park.com/www/common/mweb/img/promo/mweb-promo-video.mp4"
                                   onLoadStart={() => {
                                       this.setState({mp4Loading: true});
                                   }}
                                   onLoadedData={() => {
                                       this.setState({mp4Loading: false});
                                   }}
                            ></video>
                        </div>
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap color1">
                                    <p className="s-text">
                                        从未见过的主题乐园 <br/>
                                        体验一下线上和线下活动相结合的新乐趣吧。
                                    </p>
                                    <p className="m-text typo1">
                                        济州 <br/>
                                        No.1主题乐园，<br/>
                                        9.81乐园 。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detail-con-wrap">
                        <div className="inner">
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg1.png" alt="981 PARK"/>
                                    </div>
                                    <a href="https://www.klook.com/activity/86261-981-park-ticket-jeju/?spm=SearchResult.SearchResult_LIST&clickId=229c98b6f1" className="link link-klook" target="_blank" rel="noopener noreferrer"><span className="blind">klook</span></a>
                                    <a href="https://us.trip.com/travel-guide/attraction/Jeju/9.81%20Park%20Jeju-133524981/?locale=en-US&curr=SGD" className="link link-trip" target="_blank" rel="noopener noreferrer"><span className="blind">trip</span></a>
                                    <a href="https://www.kkday.com/en/product/131733-9-81-park-jeju-ticket-jeju-island-south-korea?qs=981" className="link link-kkday" target="_blank" rel="noopener noreferrer"><span className="blind">kkday</span></a>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg3.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg4.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg5.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg6.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg7.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg8.png" alt="981 PARK"/>
                                    </div>
                                    <a href="https://www.klook.com/activity/86261-981-park-ticket-jeju/?spm=SearchResult.SearchResult_LIST&clickId=229c98b6f1" className="link-sm link-klook" target="_blank" rel="noopener noreferrer"><span className="blind">klook</span></a>
                                    <a href="https://us.trip.com/travel-guide/attraction/Jeju/9.81%20Park%20Jeju-133524981/?locale=en-US&curr=SGD" className="link-sm link-trip" target="_blank" rel="noopener noreferrer"><span className="blind">trip</span></a>
                                    <a href="https://www.kkday.com/en/product/131733-9-81-park-jeju-ticket-jeju-island-south-korea?qs=981" className="link-sm link-kkday" target="_blank" rel="noopener noreferrer"><span className="blind">kkday</span></a>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg9.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg10.png" alt="981 PARK"/>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg11.png" alt="981 PARK"/>
                                    </div>
                                    <div className="player">
                                        <iframe width="560" height="315"
                                                src="https://www.youtube.com/embed/hBW0lulSxws?si=FfvOspzlAlqVpKF8"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/promo/mw-hotelimg12.png" alt="981 PARK"/>
                                    </div>
                                    <a href="https://youtu.be/hBW0lulSxws?feature=shared" className="player-link player-link1" target="_blank" rel="noopener noreferrer"><span className="blind">나 혼자 산다</span></a>
                                    <a href="https://youtu.be/1jJ0x4MHPLQ?feature=shared" className="player-link player-link2" target="_blank" rel="noopener noreferrer"><span className="blind">어서와 한국은 처음이지</span></a>
                                    <a href="https://youtu.be/ZYEjtxFcZvQ?feature=shared" className="player-link player-link3" target="_blank" rel="noopener noreferrer"><span className="blind">스트릿 맨 파이터</span></a>
                                    <a href="https://www.tving.com/contents/E003598936" className="player-link player-link4" target="_blank" rel="noopener noreferrer"><span className="blind">환승연애</span></a>
                                    <a href="https://youtu.be/EM_hWvyMPVg?feature=shared" className="player-link player-link5" target="_blank" rel="noopener noreferrer"><span className="blind">짠내투어</span></a>
                                    <a href="https://programs.sbs.co.kr/plus/loveforever/clip/74495/22000467108" className="player-link player-link6" target="_blank" rel="noopener noreferrer"><span className="blind">나는 SOLO</span></a>
                                    <a href="https://youtu.be/WP622BeSAWU?feature=shared" className="player-link player-link7" target="_blank" rel="noopener noreferrer"><span className="blind">SSBD Travel</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

export default MPromoHotelComp;