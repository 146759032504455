import React from 'react';
import { Link, BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MTopBannerComp from './common/MTopBannerComp'; //eslint-disable-line no-unused-vars

import { MobileHeader, MobileFooter } from '@monolith/common/dist';
import MHeaderComp from './common/MHeaderComp'; //eslint-disable-line no-unused-vars
import MFooterComp from './common/MFooterComp'; //eslint-disable-line no-unused-vars
import MMSComp from './MMSComponents';
import MPromoScootComp from './MPromoScootComp';
import MPromoHotelComp from './MPromoHotelComp';
import MPromoIjtoComp from './MPromoIjtoComp';
import MTermsComp from './common/MTermsComp';
import MRulesComp from './common/MRulesComp';
import {withTranslation} from "react-i18next";
import RoutePath from "../resource/RoutePath";
import ScrollLock from 'react-scrolllock';
import MHomeComp from './home/MHomeComp';
import {MParkComp, MActivityComp, MParkFlexComp, MConvenientComp, MShuttleComp, MOperationComp} from './park';
import {MPlayComp, MOutRaceComp, MOutHealComp, MIndoorComp, MInArenaComp, MInProArenaComp, MInMetaComp, MInRinggoComp, MInLabComp, MInCubeVerseComp, MPlayFlexComp, MFLGarageComp, MFLPhotoComp, MFLSpaceComp, MFLBroComp, MFLBoosterComp, MFLOnatzComp, MFLLoungeComp} from './play';
import {MTicketComp, MTeamRoomComp, MGroupComp} from './ticket';
import {MRaceComp, MGravityRacerComp, MLicenseComp, MBattleComp, MRankingComp, MRankingRuleComp} from './race';
import {NewMGrocComp, MAboutGROC, MHallOffFame} from './groc';
import {Race981GuideComp, EnRace981GuideComp, CnRace981GuideComp} from './guide';
import ScrollToTop from './common/ScorllToTop';
import axios from 'axios';
import { DevServer, RealServer } from "./MainComponent";
import 'url-search-params-polyfill';
import i18next from "../resource/i18n";

import { bake_cookie, read_cookie } from 'sfcookies'

class MobileComponent  extends React.Component {
	constructor(props) {
        super(props);
        this.state={
            selectMenu: RoutePath.HOME,
            popup: false,
            slock: false,
            hoursData: null,
            noSee: true
        };
        this.menuChangeHandler = this.menuChangeHandler.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.topLock = this.topLock.bind(this);
        var params = new URLSearchParams(window.location.search);
        if (params.get("lang") == "en") {
            i18next.changeLanguage('en');
        }
        if (window.location.href.includes('/guide/race981_en') || window.location.href.includes('/guide/race981_cn')) {
            i18next.changeLanguage('en');
        }
    }

    requestHours = async () => {
        var server = RealServer
        if (window.location.hostname === "localhost" || window.location.hostname === "dev-home.981park.net") {
            server = DevServer
        }
		axios({
			url:`${server}/customer/data/park/opHours`,
			method: 'get',
		  }).then(response => {
            this.setState({
                hoursData: response.data
            })
		  })
    }

    componentDidUpdate(){
        window.onpopstate  = (e) => {
            let path = window.location.pathname.replace("/", "")
            switch (path) {
                case RoutePath.HOME:
                    this.setState({selectMenu:RoutePath.HOME});
                    break;
                case RoutePath.PARK:
                case RoutePath.ACTIVITY:
                case RoutePath.PARKFLEX:
                case RoutePath.CONVENIENT:
                case RoutePath.SHUTTLE:
                case RoutePath.OPERATION:
                    this.setState({selectMenu:RoutePath.PARK});
                    break;
                case RoutePath.PLAY:
                case RoutePath.OUTRACE981:
                case RoutePath.OUTHEAL:
                case RoutePath.INDOOR:
                case RoutePath.INARENA:
                case RoutePath.INPROARENA:
                case RoutePath.INMETA:
                case RoutePath.INRINGGO:
                case RoutePath.INLAB:
                case RoutePath.INCUBE:
                case RoutePath.PLAYFLEX:
                case RoutePath.FLGARAGE:
                case RoutePath.FLPHOTO:
                case RoutePath.FLSPACE:
                case RoutePath.FLBRO:
                case RoutePath.FLLOUNGE:
                    this.setState({selectMenu:RoutePath.PLAY});
                    break;
                case RoutePath.RACE981:
                case RoutePath.GRAVITYRACER:
                case RoutePath.LICENSE:
                case RoutePath.BATTLE:
                case RoutePath.RANKING:
                case RoutePath.RANKINGRULE:
                    this.setState({selectMenu:RoutePath.RACE981});
                    break;
                case RoutePath.GROC:
                case RoutePath.ABOUTGROC:
                case RoutePath.HALLOFFAME:
                case RoutePath.GROC:
                    this.setState({selectMenu:RoutePath.GROC});
                    break;
                case RoutePath.TEAMROOM:
                case RoutePath.GROUP:
                case RoutePath.TICKET:
                    this.setState({selectMenu:RoutePath.TICKET});
                    break;
                default :
                    this.setState({selectMenu:RoutePath.HOME});
            }
        }
    }

    componentDidMount() {
        window.onpopstate  = (e) => {
        }
        require('../common/mweb/css/index.css');
        this.requestHours();
    }

    menuChangeHandler(menu) {
        this.setState({selectMenu:menu});
    }

    showPopup() {
        document.body.style.overflow = 'hidden';
        this.setState({popup:true, slock:true});
    }

    hidePopup() {
        document.body.style.overflow = 'unset';
        this.setState({popup:false, slock:false});
    }

    topLock(hidden) {
        window.scrollTo(0, 0);
        if (hidden) {
            //this.setState({slock:false});
            // menu close : body overflow auto
            document.body.className = '';

        } else {
            //this.setState({slock:true});
            // menu close : body overflow hidden
            document.body.className = 'hidden';
        }
    }

    closeNosee = () => () => {
        this.setState({noSee: false})
	}

	render() {
        const {t} = this.props;
        var ePopup = <div className="layer-popup race981-popup">
                <div className="layer-popup-inner">
                    <div className="content">
                        <button type="button" className="btn close" onClick={this.closeNosee()}><span className="blind">닫기</span></button>
                        <div className="con">
                            <div className="txt-wrap">
                                <div className="ttl">
                                    <span>{t('Notice_title')}</span>
                                </div>
                                <div className="txt">
                                    <span>{t('Notice_content1') + t('Notice_content2') + t('Notice_content3')}</span><br/>
                                    <span>{t('Notice_content6')}</span><br/>
                                    <span>{t('Notice_content4')}</span>
							        <span>{t('Notice_content5')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        if (!this.state.noSee) {
            ePopup = null
        }
        ePopup = null
        return (
            <Router>
                <ScrollLock isActive={this.state.slock} />
                <ScrollToTop>
                    <Switch>
                        <Route exact path={`/` + RoutePath.MMS}>
                            <MMSComp/>
                        </Route>
                        <Route exact path={`/` + RoutePath.PROMOSCOOT}>
                            <Redirect to={`/` + RoutePath.PROMOIJTO} />
                        </Route>
                        <Route exact path={`/` + RoutePath.PROMOIJTO}>
                            <MPromoIjtoComp/>
                        </Route>
                        <Route exact path={`/` + RoutePath.PROMOHOTEL}>
                            <MPromoHotelComp/>
                        </Route>

                        <Route exact path={`/` + RoutePath.HOME}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MHomeComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PARK}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MParkComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ParkAbout}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MParkComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ACTIVITY}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MActivityComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PARKFLEX}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MParkFlexComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.CONVENIENT}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MConvenientComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.SHUTTLE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MShuttleComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OPERATION}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MOperationComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PLAY}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MPlayComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OUTRACE981}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MOutRaceComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OUTHEAL}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MOutHealComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INDOOR}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MIndoorComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.Lab981}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MIndoorComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INARENA}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInArenaComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INPROARENA}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInProArenaComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INMETA}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInMetaComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INCUBE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInCubeVerseComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INRINGGO}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInRinggoComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INLAB}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MInLabComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PLAYFLEX}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MPlayFlexComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLGARAGE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLGarageComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLPHOTO}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLPhotoComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLSPACE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLSpaceComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLBRO}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLBroComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLBOOSTER}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLBoosterComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLONATZ}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLOnatzComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLLOUNGE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MFLLoungeComp/>
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RACE981}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MRaceComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GRAVITYRACER}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MGravityRacerComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.LICENSE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MLicenseComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.BATTLE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MBattleComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RANKING}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MRankingComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RANKINGRULE}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MRankingRuleComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GROC}>
                            <div className="park-wrap groc-fullscreen">
                                <MobileHeader topLock={this.topLock}/>
                                <NewMGrocComp />
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ABOUTGROC}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MAboutGROC />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.HALLOFFAME}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MHallOffFame />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TICKET}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MTicketComp showPopup={this.showPopup} />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GROUP}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MGroupComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TEAMROOM}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MTeamRoomComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TERMS}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MTermsComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.Notice}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MTermsComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RULES}>
                            <div className="park-wrap">
                                <MobileHeader topLock={this.topLock}/>
                                <MRulesComp />
                                <MobileFooter/>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GUIDE + `/` + RoutePath.RACE981+ `_ko`}>
                            <div className="park-wrap hearing-impaired">
                                <div className="header">
                                    <div className="head-wrap">
                                        <h1 className="logo"><Link to={`/`} onClick={this.hidePopup}><img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/race-981-logo-white.svg" alt="race981"/></Link></h1>
                                    </div>
                                </div>
                                <Race981GuideComp />
                                <div className="link-top-wrap">
                                    <button onClick={() => {window.scrollTo(0,0);}} className="link-top"><span className="blind">top</span></button>
                                </div>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GUIDE + `/` + RoutePath.RACE981+ `_en`}>
                            <div className="park-wrap hearing-impaired en-hearing-impaired">
                                <div className="header">
                                    <div className="head-wrap">
                                        <h1 className="logo"><Link to={`/?lang=en`} onClick={this.hidePopup}><img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/race-981-logo-white.svg" alt="race981"/></Link></h1>
                                    </div>
                                </div>
                                <EnRace981GuideComp />
                                <div className="link-top-wrap">
                                    <button onClick={() => {window.scrollTo(0,0);}} className="link-top"><span className="blind">top</span></button>
                                </div>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GUIDE + `/` + RoutePath.RACE981+ `_cn`}>
                            <div className="park-wrap hearing-impaired cn-hearing-impaired">
                                <div className="header">
                                    <div className="head-wrap">
                                        <h1 className="logo"><Link to={`/?lang=en`} onClick={this.hidePopup}><img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/race-981-logo-white.svg" alt="race981"/></Link></h1>
                                    </div>
                                </div>
                                <CnRace981GuideComp />
                                <div className="link-top-wrap">
                                    <button onClick={() => {window.scrollTo(0,0);}} className="link-top"><span className="blind">top</span></button>
                                </div>
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GUIDE}>
                            <div className="park-wrap hearing-impaired">
                                <div className="header">
                                    <div className="head-wrap">
                                        <h1 className="logo"><Link to={`/`} onClick={this.hidePopup}><img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/race-981-logo-white.svg" alt="race981"/></Link></h1>
                                    </div>
                                </div>
                                <Race981GuideComp />
                                <div className="link-top-wrap">
                                    <button onClick={() => {window.scrollTo(0,0);}} className="link-top"><span className="blind">top</span></button>
                                </div>
                                {ePopup}
                            </div>
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
		);
	}
}
export default withTranslation()(MobileComponent);